import { Col, Row } from "antd";
import SvgIcon from "../common/SvgIcon";
import "./style.css";

const Fallback = () => (
  <Row justify="center" align="middle" style={{ height: "100vh" }}>
    <Col>
      <center>
        <SvgIcon src="logo.svg" width={150} height={80} />
        <div className="loader"></div>
      </center>
    </Col>
  </Row>
);

export default Fallback;
