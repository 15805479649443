import createStore from "zustand";
import { devtools } from "zustand/middleware";
import { configurePersist } from "zustand-persist";

const { persist } = configurePersist({
  storage: localStorage, // use `AsyncStorage` in react native
  rootKey: "root", // optional, default value is `root`
});
/**
 * @name store
 * @description get and set function for all state managements
 * */
let store = (set) => ({
  apiResponse: {},
  setApiResponse: (apiResponse) => set({ apiResponse }),
  cartOverview: { cartValue: 0, productCount: 0 },
  setCartOverview: (cartOverview) => set({ cartOverview }),
  searchFields: {},
  setSearchFields: (searchFields) => set({ searchFields }),
  planForm: {
    dental_benefits: 51,
    vision_benefits: 51,
    dental_package_type: 3,
  },
  setPlanForm: (planForm) => set({ planForm }),
  applicantForm: {},
  setApplicantForm: (applicantForm) => set({ applicantForm }),
  prevInsuranceForm: {},
  setPrevInsuranceForm: (prevInsuranceForm) => set({ prevInsuranceForm }),
  paymentInfoForm: {},
  setPaymentInfoForm: (paymentInfoForm) => set({ paymentInfoForm }),
  appID: "",
  setAppID: (appID) => set({ appID }),
  validateAgentData: {},
  setValidateAgentData: (validateAgentData) => set({ validateAgentData }),
  agentLicenses: [],  
  setAgentLicense: (agentLicenses) => set({ agentLicenses }),
  selectedState: "",
  setSelectedState: (selectedState) => set({ selectedState }),
  agentDetails: {},
  setAgentData: (agentDetails) => set({ agentDetails }),
  current: 0,
  setCurrent: (current) => set({ current}),
});

store = devtools(store);
/**
 * @name useStore
 * @description this creates user store
 * */
export const useStore = createStore(
  persist(
    {
      key: "auth",
    },
    store
  )
);
