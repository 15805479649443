import { useState, Fragment, lazy, setState } from "react";
import { Row, Col, Select, Menu, Dropdown, Button, Space } from "antd";
import { Link } from "react-router-dom";
import * as S from "./styles";
import { useStore } from "../../Store";
import { useMatchMedia } from "../../common/mediaquery";
import queryString from "query-string";
import "../../router/style.css";

const SvgIcon = lazy(() => import("../../common/SvgIcon"));

const Header = ({ t }) => {
  let isLoggedIn = false;
  const isMobile = useMatchMedia("(min-width:800px)", true);
  const { agentDetails } = useStore((state) => ({
    agentDetails: state.agentDetails,
  }));
  const windowUrl = window.location.search;
  const params = queryString.parse(windowUrl);
  if (params["agentID"]) {
    isLoggedIn = true;
  } else {
    isLoggedIn = false;
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" href="https://ncd.com/dentists">
          Dental Search
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" href="https://www.vsp.com/eye-doctor">
          Vision Search
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="sunset-banner">
        <div>
          <p className="sunset-banner-heading">
            Attention! Starting Jan 31, 2024, NCD dental plans under Nationwide
            are no longer accepting new enrollments.
          </p>
          <p className="sunset-banner-desc">
            Switch to NCD by MetLife for up to $10k annual max benefit, a vast
            network, quick claims, and excellent in-network discounts.
          </p>
        </div>

        <a
          className="sunset-banner-cta"
          href="https://enrollment.ncd.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Open Enrollment NCD website in a new tab"
        >
          Click to quote and enroll now!
        </a>
      </div>

      <S.Header>
        <S.Container>
          <Row type="flex" justify="space-between" gutter={24}>
            <Col xl={16} xs={14}>
              <S.LogoContainer aria-label="homepage">
                <Row
                  type="flex"
                  gutter={24}
                  align="middle"
                  style={{ height: "100%" }}
                >
                  <Col xl={6}>
                    <Link to={`/?agentID=${agentDetails.agentId}`}>
                      <SvgIcon src="logo.svg" width={150} height={80} />
                    </Link>
                  </Col>
                  <Col
                    xl={2}
                    style={{ marginRight: 5 }}
                    className="extra-logos"
                  >
                    <p style={{ fontSize: 8 }}>Dental underwritten by</p>
                  </Col>
                  <Col xl={5} className="extra-logos">
                    <SvgIcon
                      src="nationwide_logo.svg"
                      style={{ verticalAlign: "bottom" }}
                      height={50}
                    />
                  </Col>
                  <Col
                    xl={2}
                    style={{ marginRight: 5 }}
                    className="extra-logos"
                  >
                    <p style={{ fontSize: 8 }}>Vision underwritten by</p>
                  </Col>
                  <Col xl={5} className="extra-logos">
                    <SvgIcon
                      src="vsp_logo_v2.svg"
                      style={{ verticalAlign: "bottom" }}
                      height={50}
                    />
                  </Col>
                </Row>
              </S.LogoContainer>
            </Col>
            {isMobile && isLoggedIn && (
              <Col
                xl={4}
                xs={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Button
                    style={{
                      backgroundColor: "#469dc9",
                      color: "white",
                      width: "150px",
                      height: "40px",
                    }}
                  >
                    Find a Doc
                  </Button>
                </Dropdown>
              </Col>
            )}
            <Col xl={4} xs={5}>
              {!!Object.keys(agentDetails).length && isLoggedIn && (
                <S.CallBox>
                  <span className="light-span">Agent Name</span>
                  <h3>
                    <b>{agentDetails.name}</b>
                  </h3>
                  <h5>{createPhoneNumber(agentDetails.number)}</h5>
                  <h5>{agentDetails.email}</h5>
                </S.CallBox>
              )}
              {!!Object.keys(agentDetails).length &&
                isLoggedIn &&
                !isMobile && (
                  <Row
                    type="flex"
                    gutter={24}
                    align="middle"
                    style={{
                      float: "right",
                      paddingRight: "22px",
                      paddingBottom: "15px",
                    }}
                  >
                    <Dropdown overlay={menu} placement="bottomLeft">
                      <Button
                        style={{
                          backgroundColor: "#469dc9",
                          color: "white",
                          width: "150px",
                          height: "40px",
                        }}
                      >
                        Find a Doc
                      </Button>
                    </Dropdown>
                  </Row>
                )}
            </Col>
            {/* <Col xl={4} xs={14}>
            <S.CallBox>
              <span className="light-span">Need help?</span>
              <h3>
                <b>{agentDetails.number || "(888)-415-4801"}</b>
              </h3>
              <h5>Mon-Fri 8am-6pm(CST)</h5>
            </S.CallBox>
          </Col> */}
          </Row>
        </S.Container>
      </S.Header>
    </>
  );
};
function createPhoneNumber(numb) {
  let numbers = numb;
  if (!Array.isArray(numb)) numbers = Array.from(String(numb), Number);
  var format = "(xxx) xxx-xxxx";

  for (var i = 0; i < numbers.length; i++) {
    format = format.replace("x", numbers[i]);
  }

  return format;
}
export default Header;
